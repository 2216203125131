import React from 'react';
import { useLocation } from '@reach/router';

import ContactPharmaMessager from 'components/contact-pharma-messager/ContactPharmaMessager';
import {
    accountGetAskThePharmMessageThreadRoutine,
    accountFetchAskThePharmReplyMessageRoutine
} from 'state/account/account.routines';
import { useDispatch } from 'react-redux';
import { messageOptions } from 'const/options';
import { navigate } from 'gatsby';

export default function AskPharm() {
    const location = useLocation();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(location.search);
    const headerId = urlParams.get('header_id');
    if (urlParams.has('header_id')) {
        dispatch(
            accountGetAskThePharmMessageThreadRoutine.trigger({
                HeaderID: headerId
            })
        );
        dispatch(
            accountFetchAskThePharmReplyMessageRoutine.trigger({
                HeaderID: headerId,
                messageAction: 'OPEN'
            })
        );
    } else {
        if (typeof window !== 'undefined') {
            navigate('/404');
        }
    }
    return <ContactPharmaMessager messageSource={messageOptions[0].key} headerId={headerId} />;
}
